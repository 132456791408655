import React, { Component } from 'react';

class Contact extends Component {
   componentDidMount() {
      // update this with your js_form selector
      var data_js = {
            "access_token": "5zz7wkatgwgj80mbxdnz4s7o"
      };
      
      var sendButton = document.getElementsByTagName("button")[0];
      function js_send() {
            var request = new XMLHttpRequest();
            request.onreadystatechange = function() {
                  if (request.readyState === 4 && request.status === 200) {
                        window.alert("Message sent successfully!");
                  }
            };
      
            var name = document.querySelector("#contactName").value;
            var email = document.querySelector("#contactEmail").value;
            var subject = document.querySelector("#contactSubject").value;
            var message = document.querySelector("#contactMessage").value;
      
            data_js['subject'] = subject;
            data_js['text'] = name + '\n' + email + '\n\n\n' + message;
            var params = toParams(data_js);
      
            request.open("POST", "https://postmail.invotes.com/send", true);
            request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            request.send(params);
      
            return false;
      }
      sendButton.onclick = js_send;
      
      function toParams(data_js) {
            var form_data = [];
            for ( var key in data_js ) {
                  form_data.push(encodeURIComponent(key) + "=" + encodeURIComponent(data_js[key]));
            }
            return form_data.join("&");
      }
      
      const js_form = document.querySelector("form");
      js_form.addEventListener("submit", function (e) {e.preventDefault();});
   }

  render() {

    if (this.props.data) {
      var message = this.props.data.contactmessage;
    }

    return (
      <section id="contact">
         <div className="row section-head">
            <div className="two columns header-col">
               <h1><span>Get In Touch.</span></h1>
            </div>

            <div className="ten columns">
                  <p className="lead">{message}</p>
            </div>
         </div>

         <div className="row">
            <div className="twelve columns">

               <form action="" method="post" id="contactForm" name="contactForm">
					<fieldset>

                  <div>
						   <label htmlFor="contactName">Name <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactName" name="contactName" onChange={this.handleChange}/>
                  </div>

                  <div>
						   <label htmlFor="contactEmail">Email <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactEmail" name="contactEmail" onChange={this.handleChange}/>
                  </div>

                  <div>
						   <label htmlFor="contactSubject">Subject</label>
						   <input type="text" defaultValue="" size="35" id="contactSubject" name="contactSubject" onChange={this.handleChange}/>
                  </div>

                  <div>
                     <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                     <textarea cols="50" rows="15" id="contactMessage" name="contactMessage"></textarea>
                  </div>

                  <div>
                     <button className="submit">Submit</button>
                     <span id="image-loader">
                        <img alt="" src="images/loader.gif" />
                     </span>
                  </div>
					</fieldset>
				   </form>
           </div>
      </div>
   </section>
    );
  }
}

export default Contact;
