import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(education => {
        return (
            <div key={education.school}>
              <h3>{education.school}</h3>
              <p className="info">
                {education.degree} <span>&bull;</span><em className="date">{education.graduated}</em>
              </p>
              <p>{education.description}</p>
            </div>
        )
      })

      var work = this.props.data.work.map(work => {
        return (
            <div key={work.company}>
                <h3>{work.company}</h3>
                <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
                <p>{work.description}</p>
            </div>
        )
      })

      var skills = this.props.data.skills.map(skills => {
        var className = 'bar-expand ' + skills.name.toLowerCase();
        return (
            <li key={skills.name}>
                <span style={{width:skills.level}}className={className}></span><em>{skills.name}</em>
            </li>
        )
      })

        var certifications = this.props.data.certifications.map(certification => {
            return (
                <div key={certification.name}>
                    <br/><br/>
                    <div className="two columns">
                        <img alt={certification.name} src={certification.image}
                             onClick={() => window.open(certification.url)}/>
                    </div>
                    <div className="ten columns" onClick={() => window.open(certification.url)}>
                        <h3>{certification.name}</h3>
                        <p className="info">Validation Code / ID<span>-</span> <em
                            className="date">{certification.id}</em></p>
                    </div>
                </div>
            )
        })
    }

      return (
          <section id="resume">
              <div className="row work">
                  <div className="three columns header-col">
                      <h1><span>Work</span></h1>
                  </div>
                  <div className="nine columns main-col">
                      {work}
                  </div>
              </div>

              <div className="row education">
                  <div className="three columns header-col">
                      <h1><span>Education</span></h1>
                  </div>
                  <div className="nine columns main-col">
                      <div className="row item">
                          <div className="twelve columns">
                              {education}
                          </div>
                      </div>
                  </div>
              </div>

              <div className="row skill">
                  <div className="three columns header-col">
                      <h1><span>Skills</span></h1>
                  </div>
                  <div className="nine columns main-col">
                      <p>{skillmessage}
                      </p>
                      <div className="bars">
                          <ul className="skills">
                              {skills}
                          </ul>
                      </div>
                  </div>
              </div>

              <div className="row certification">
                  <div className="three columns header-col">
                      <h1><span>Certifications</span></h1>
                  </div>
                  <div className="nine columns main-col">
                      <div className="row item">
                          <div className="twelve columns">
                              {certifications}
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      );
  }
}

export default Resume;
